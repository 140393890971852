import _ from "lodash";

export const filters = {
  initials(str) {
    const words = _.words(str, /[\w']+/g);

    if (words.length > 2) {
      const firstWord = _.head(words);
      const lastWord = _.last(words);

      return [firstWord, lastWord].map((w) => _.head(w)).join("");
    } else {
      return words.map((w) => _.head(w)).join("");
    }
  },
  gutMiddle(str, limit = 24) {
    const oneThird = _.toInteger(limit / 3);

    if (str.length > limit) {
      return (
        str.substr(0, oneThird) +
        "..." +
        str.substr(str.length - oneThird, str.length)
      );
    }

    return str;
  },
};
